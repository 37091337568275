var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { requestNotifyClear } from '../../controller/actions';
import styles from './index.scss';
var TIMER_ID = null;
var NotifyPopUp = memo(function (_a) {
    var errorMessage = _a.errorMessage, successMessage = _a.successMessage, runRemovePopUp = _a.runRemovePopUp;
    var _b = __read(useState(true), 2), isAppear = _b[0], setAppearFlag = _b[1];
    useEffect(function () {
        TIMER_ID = setTimeout(function () {
            setAppearFlag(!isAppear);
        }, 5000);
        return function () { return clearTimeout(TIMER_ID); };
    }, [!!errorMessage, !!successMessage]);
    if (!errorMessage && !successMessage) {
        return null;
    }
    return (React.createElement(CSSTransition, { appear: true, in: (!!errorMessage || !!successMessage) && isAppear, classNames: 'errorPopup', timeout: { enter: 500, exit: 500 }, unmountOnExit: true, onExited: function () { return runRemovePopUp(); } },
        React.createElement("div", { className: successMessage ? styles.successWrap : styles.errorWrap },
            React.createElement("span", null, successMessage ? "".concat(successMessage, " \uD83C\uDF89") : errorMessage))));
});
var mapStateToProps = function (_a) {
    var app = _a.app;
    return ({
        successMessage: app.success,
        errorMessage: app.error
    });
};
var mapDispatchToProps = {
    runRemovePopUp: requestNotifyClear
};
export default connect(mapStateToProps, mapDispatchToProps)(NotifyPopUp);
