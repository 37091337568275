import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.scss';
var Body = memo(function (_a) {
    var children = _a.children, isDonate = _a.isDonate;
    var is = useSelector(function (_a) {
        var browser = _a.browser;
        return browser;
    }).is;
    return (React.createElement("div", { className: "".concat(styles.body, " ").concat(!is.desktop ? styles.sticky : '', " ").concat(isDonate ? styles.withDonate : '') }, children));
});
Body.displayName = 'Body';
export default Body;
