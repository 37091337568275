// @ts-nocheck
import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Frontload } from 'react-frontload';
import CoreLayout from '../layout';
var AppRouting = function (_a) {
    var store = _a.store, history = _a.history;
    return (React.createElement(Provider, { store: store },
        React.createElement(ConnectedRouter, { history: history, basename: 'en' },
            React.createElement(Frontload, { noServerRender: true },
                React.createElement(CoreLayout, null)))));
};
export default AppRouting;
