var _a;
export var BUTTONS_LANGUAGES = [
    {
        label: 'en',
        icon: 'english'
    },
    {
        label: 'ua',
        icon: 'ukrainian'
    },
    {
        label: 'ru',
        icon: 'russian'
    }
];
export var LANGUAGE_MAP = (_a = {},
    _a[BUTTONS_LANGUAGES[0].label] = { label: 'en', dir: 'ltr', active: true },
    _a[BUTTONS_LANGUAGES[1].label] = { label: 'ua', dir: 'ltr', active: false },
    _a[BUTTONS_LANGUAGES[2].label] = { label: 'ru', dir: 'ltr', active: false },
    _a);
