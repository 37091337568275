import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { NotifyPopUp, Header, Body, Footer, ScrollToTop } from '../components';
import { Home, PageNotFound, Privacy, AboutUs, Contact, Logout, Login, Registration, Profile } from '../routes';
import { LiveChat, CookiePanel, OathWrapper } from '../services';
import DonateUkraine from '../components/DonateUkraine';
import getLangPrefix from '../utils/gelLangPrefix';
// eslint-disable-next-line import/no-unassigned-import
import '../../i18nextConf';
import '../styles/index.scss';
import styles from './index.scss';
var CoreLayout = function (_a) {
    var language = _a.language, isDarkMode = _a.isDarkMode;
    var lang = getLangPrefix(language);
    return (React.createElement(OathWrapper, null,
        React.createElement("div", { id: isDarkMode ? 'darkMode' : '', className: styles.appWrapper },
            React.createElement(NotifyPopUp, null),
            React.createElement(DonateUkraine, null),
            React.createElement(Header, null),
            React.createElement(Body, { isDonate: true },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: lang, component: Home }),
                    React.createElement(Route, { path: "".concat(lang, "contact"), component: Contact }),
                    React.createElement(Route, { path: "".concat(lang, "about-us"), component: AboutUs }),
                    React.createElement(Route, { path: "".concat(lang, "sign-out"), component: Logout }),
                    React.createElement(Route, { path: "".concat(lang, "sign-in"), component: Login }),
                    React.createElement(Route, { path: "".concat(lang, "sign-up"), component: Registration }),
                    React.createElement(Route, { path: "".concat(lang, "profile"), component: Profile }),
                    React.createElement(Route, { path: "".concat(lang, "policy"), component: Privacy }),
                    React.createElement(Route, { path: '*', component: PageNotFound }))),
            React.createElement(Footer, null),
            React.createElement(LiveChat, null),
            React.createElement(ScrollToTop, null),
            React.createElement(CookiePanel, null))));
};
// console.log(document, 'document');
// const SSR_ROOT = document && document.querySelector('#react-ssr-app-root');
// const CoreLayoutSSR = (props) => {
//   Loadable.preloadReady().then((payload) => {
//     console.log(props, payload, window.__PRELOADED_STATE__, 'test')
//     ReactDOM.hydrate(<CoreLayout />, SSR_ROOT);
//   });
// };
// const CoreLayoutCSR = () => <CoreLayout />;
// SSR_ROOT && CoreLayoutSSR();
var mapStateToProps = function (state) { return ({
    language: state.app.locale,
    isDarkMode: state.app.isDarkMode
}); };
export default connect(mapStateToProps, null)(CoreLayout);
