var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
// some reducers here. The same link in on top level
import initialState from './initialState';
import { CAR_ATTEMPT, CAR_SAVED, CAR_ERROR } from '../constants';
import { WS_DISCONNECTED } from '../../../constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
// you need to fill all of your reducers cases inside this object like an arrow functions
var ACTION_HANDLERS = (_a = {},
    _a[CAR_ATTEMPT] = function (state, action) { return (__assign(__assign({}, state), { searchCarNumber: action.carNumber, statuses: {
            isSearchInProgress: true,
            isSearchError: false
        } })); },
    _a[CAR_SAVED] = function (state, action) { return (__assign(__assign({}, state), { carData: action.payload, statuses: {
            isSearchError: !action.payload,
            isSearchInProgress: false
        } })); },
    _a[CAR_ERROR] = function (state) { return (__assign(__assign({}, state), { statuses: {
            isSearchError: true,
            isSearchInProgress: false
        }, error: null })); },
    _a[WS_DISCONNECTED] = function (state) { return (__assign(__assign({}, state), { statuses: {
            // eslint-disable-next-line react/destructuring-assignment
            isSearchError: state.statuses.isSearchInProgress,
            isSearchInProgress: false
        }, error: null })); },
    _a);
// ------------------------------------
// Reducer
// ------------------------------------
// you need to place here right typization and initialState bind
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default reducer;
