var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
// some reducers here. The same link in on top level
import initialState from './initialState';
import { USER_SOCIAL_DATA, LOGIN_ATTEMPT, LOGIN_SAVED, LOGIN_ERROR, LOGIN_INPUT } from '../constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
// you need to fill all of your reducers cases inside this object like an arrow functions
var ACTION_HANDLERS = (_a = {},
    _a[LOGIN_ATTEMPT] = function (state) { return (__assign(__assign({}, state), { isLoginFetch: true })); },
    _a[USER_SOCIAL_DATA] = function (state, action) { return (__assign(__assign({}, state), { isSocial: true, userdata: action.email, token: action.token, isLoginFetch: true })); },
    _a[LOGIN_SAVED] = function (state) { return (__assign(__assign({}, state), { password: '', userdata: '', isLoginFetch: false })); },
    _a[LOGIN_ERROR] = function (state, action) { return (__assign(__assign({}, state), { isLoginFetch: false, error: action.error })); },
    _a[LOGIN_INPUT] = function (state, action) {
        var _a;
        return (__assign(__assign({}, state), (_a = {}, _a[action.id] = action.value, _a)));
    },
    _a);
// ------------------------------------
// Reducer
// ------------------------------------
// you need to place here right typization and initialState bind
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default reducer;
