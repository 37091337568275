import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styles from './index.scss';
var Link = function (_a) {
    var isNotSPA = _a.isNotSPA, to = _a.to, children = _a.children, navigateSPA = _a.navigateSPA, className = _a.className, type = _a.type, withBackground = _a.withBackground, locale = _a.locale;
    var isUALanguage = locale === 'ua';
    var navigationLocale = isUALanguage ? '' : "".concat(locale);
    var linkTo = "".concat(navigationLocale ? '/' : '').concat(navigationLocale).concat(to);
    var linkToNormalized = linkTo.replace(/(\/\/\/)/i, '/');
    var handleClick = function (e) {
        if (isNotSPA) {
            return;
        }
        e.preventDefault();
        navigateSPA(linkToNormalized);
    };
    var content = typeof children === 'string' ? React.createElement("span", { className: styles.text }, children) : children;
    return (React.createElement("a", { href: isNotSPA ? to : linkToNormalized, onClick: handleClick, className: "".concat(styles.linkSPA, " ").concat(type ? styles[type] : '', " ").concat(withBackground ? styles.withBackground : '', " ").concat(className) }, content));
};
var mapDispatchToProps = function (state) { return ({
    locale: state.app.locale
}); };
var mapDispatchToState = {
    navigateSPA: push
};
export default connect(mapDispatchToProps, mapDispatchToState)(Link);
