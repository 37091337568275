import isLocalDev from '../isLocalDev';
var LOCAL_FRONTEND_SERVER = 'https://localhost:3000';
var LOCAL_BACKEND_SERVER = 'https://localhost:3002';
var REMOTE_SERVER = 'https://carradar.com.ua';
var getLocalHost = function () {
    var _a = isLocalDev(), isLocalBackend = _a.isLocalBackend, isLocalFrontend = _a.isLocalFrontend;
    if (isLocalFrontend) {
        return LOCAL_FRONTEND_SERVER;
    }
    if (isLocalBackend) {
        return LOCAL_BACKEND_SERVER;
    }
    return REMOTE_SERVER;
};
export default getLocalHost;
