import { TOGGLE_CHAT, SENT_MESSAGE, RECEIVE_MESSAGE, WRITTING_MESSAGE, CLEAR_ERROR, CONNECTION_ERROR, SET_NEW_MESSAGES_COUNT } from '../../constants';
var toggleChat = function (imageID) { return ({
    type: TOGGLE_CHAT,
    imageID: imageID
}); };
var writeMessage = function (message) { return ({
    type: WRITTING_MESSAGE,
    message: message
}); };
var sentMessage = function (_a) {
    var text = _a.text, timestamp = _a.timestamp;
    return ({
        type: SENT_MESSAGE,
        text: text,
        timestamp: timestamp
    });
};
var receiveMessage = function (_a) {
    var text = _a.text, timestamp = _a.timestamp;
    return ({
        type: RECEIVE_MESSAGE,
        text: text,
        timestamp: timestamp
    });
};
var clearError = function () { return ({
    type: CLEAR_ERROR
}); };
var connectionError = function () { return ({
    type: CONNECTION_ERROR
}); };
var setNewMessagesCount = function (count) { return ({
    type: SET_NEW_MESSAGES_COUNT,
    count: count
}); };
export { toggleChat, sentMessage, receiveMessage, writeMessage, clearError, connectionError, setNewMessagesCount };
