// @ts-nocheck
// import { LOCATION_CHANGE } from 'connected-react-router';
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { NEWSLETTER_ATTEMPT, NEWSLETTER_ERROR, NEWSLETTER_SAVED, REQUEST_NOTIFY_ERROR, REQUEST_NOTIFY_SUCCESS, REQUEST_NOTIFY_CLEAR, SET_LOCALE, SET_LOGGED, TOGGLE_DARK_MODE } from '../../constants/index';
import initialState from '../initialState';
// ------------------------------------
// Action Handlers
// ------------------------------------
var ACTION_HANDLERS = (_a = {},
    _a[REQUEST_NOTIFY_ERROR] = function (state, action) { return (__assign(__assign({}, state), { error: action.errorMessage })); },
    _a[REQUEST_NOTIFY_SUCCESS] = function (state, action) { return (__assign(__assign({}, state), { success: action.successMessage })); },
    _a[REQUEST_NOTIFY_CLEAR] = function (state) { return (__assign(__assign({}, state), { error: null })); },
    _a[SET_LOCALE] = function (state, action) { return (__assign(__assign({}, state), { locale: action.language })); },
    _a[SET_LOGGED] = function (state, action) { return (__assign(__assign({}, state), { isLogged: action.isLogged })); },
    _a[NEWSLETTER_ATTEMPT] = function (state, action) { return (__assign(__assign({}, state), { subscribeNewsletter: __assign(__assign({}, state.subscribeNewsletter), { userEmail: action.userEmail, isInProgress: true }) })); },
    _a[NEWSLETTER_SAVED] = function (state, action) { return (__assign(__assign({}, state), { subscribeNewsletter: __assign(__assign({}, state.subscribeNewsletter), { isInProgress: false, error: action.error, success: action.success }) })); },
    _a[NEWSLETTER_ERROR] = function (state, action) { return (__assign(__assign({}, state), { subscribeNewsletter: __assign(__assign({}, state.subscribeNewsletter), { error: action.error, isInProgress: false }) })); },
    _a[TOGGLE_DARK_MODE] = function (state) { return (__assign(__assign({}, state), { isDarkMode: !state.isDarkMode })); },
    _a);
// ------------------------------------
// Reducer
// ------------------------------------
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default reducer;
