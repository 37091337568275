var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Logo from './Logo';
import Links from './Links';
import Language from './Language';
import DarkModeSwitcher from '../DarkModeSwitcher';
import Sidebar from '../Sidebar';
import styles from './index.scss';
import isDesktop from '../../utils/isDesktop';
var DesktopMenuLayout = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Links, null),
        React.createElement(Language, null),
        React.createElement(DarkModeSwitcher, null)));
};
var TouchscreenMenuLayout = function () {
    var _a = __read(useState(false), 2), isBurgerClicked = _a[0], toggleBurger = _a[1];
    var _handleClose = function () { return toggleBurger(!isBurgerClicked); };
    return (React.createElement("div", { className: styles.mobileMenu },
        React.createElement(Language, null),
        React.createElement("button", { type: 'button', className: styles.burgerButton, onClick: _handleClose, tabIndex: 0, "aria-label": "Click to ".concat(!isBurgerClicked ? 'open' : 'close', " menu"), onKeyDown: undefined }, Array.from(Array(4).keys()).map(function (key) { return (React.createElement("span", { key: key, className: styles.burgerIconSlice })); })),
        React.createElement(Sidebar, { isOpen: isBurgerClicked, onClickClose: toggleBurger })));
};
var Header = function () {
    var isDesktopLayout = useSelector(isDesktop);
    return (React.createElement("div", { className: "".concat(styles.headerWrap, " ").concat(!isDesktopLayout ? styles.sticky : '') },
        React.createElement("div", { id: 'header', className: styles.header },
            React.createElement(Logo, null),
            isDesktopLayout ? React.createElement(DesktopMenuLayout, null) : React.createElement(TouchscreenMenuLayout, null))));
};
Header.displayName = 'Header';
export default Header;
