/**
 *  @name isDev
 *  @author sviat
 *  @version 1.0.0
 *  @description suitable way for checking current project's domain
 *
 *  @readonly
 */
var isLocalhost = function () {
    if (typeof window === 'undefined') {
        return false;
    }
    var host = window.location.host;
    return host.includes('localhost');
};
export default isLocalhost;
