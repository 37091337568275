var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
// some reducers here. The same link in on top level
import initialState from './initialState';
import { REGISTRATION_ATTEMPT, REGISTRATION_SAVED, REGISTRATION_ERROR, REGISTRATION_INPUT, SOCIAL_REGISTRATION_DATA } from '../constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
// you need to fill all of your reducers cases inside this object like an arrow functions
var ACTION_HANDLERS = (_a = {},
    _a[SOCIAL_REGISTRATION_DATA] = function (state, action) { return (__assign(__assign({}, state), { email: action.email, error: null, password: '', passwordConfirmation: '', username: action.username, usersurname: action.usersurname, imageurl: action.imagesrc, isRegistrationFetch: true, isSocialRegistration: true })); },
    _a[REGISTRATION_ATTEMPT] = function (state) { return (__assign(__assign({}, state), { isRegistrationFetch: true })); },
    _a[REGISTRATION_SAVED] = function (state) { return (__assign(__assign({}, state), { email: '', error: null, isRegistrationFetch: false, password: '', passwordConfirmation: '', username: '', usersurname: '', imageurl: '' })); },
    _a[REGISTRATION_ERROR] = function (state, action) { return (__assign(__assign({}, state), { isRegistrationFetch: false, error: action.error })); },
    _a[REGISTRATION_INPUT] = function (state, action) {
        var _a;
        return (__assign(__assign({}, state), (_a = {}, _a[action.id] = action.value, _a)));
    },
    _a);
// ------------------------------------
// Reducer
// ------------------------------------
// you need to place here right typization and initialState bind
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default reducer;
