export var LOCALES_ADAPTER = {
    'en-GB': 'en',
    'en-US': 'en',
    'ua-UA': 'ua',
    'ru-RU': 'ru',
    'en-gb': 'en',
    'en-us': 'en',
    'ua-ua': 'ua',
    'ru-ru': 'ru',
    en: 'en',
    ua: 'ua',
    ru: 'ru'
};
