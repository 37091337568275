export var REQUEST_NOTIFY_ERROR = 'REQUEST_NOTIFY_ERROR';
export var REQUEST_NOTIFY_SUCCESS = 'REQUEST_NOTIFY_SUCCESS';
export var REQUEST_NOTIFY_CLEAR = 'REQUEST_NOTIFY_CLEAR';
export var SET_LOCALE = 'SET_LOCALE';
export var SET_LOGGED = 'SET_LOGGED';
export var TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export var NEWSLETTER_ATTEMPT = 'NEWSLETTER_ATTEMPT';
export var NEWSLETTER_SAVED = 'NEWSLETTER_SAVED';
export var NEWSLETTER_ERROR = 'NEWSLETTER_ERROR';
export var WS_CONNECTED = 'WS_CONNECTED';
export var WS_DISCONNECTED = 'WS_DISCONNECTED';
export var WS_MESSAGE = 'WS_MESSAGE';
export var WS_SEND_MESSAGE = 'WS_SEND_MESSAGE';
