/**
 *  @name isValue
 *  @author info@carradar.com.ua
 *  @version 1.1.0
 *  @description helps recognize is received value originally provided even with "0" or "false",
 *  @description rather than real "false" value.
 *
 *  @params {string | number | boolean | object} value - some unit to check on
 *
 *  @return {boolean} value - real unit condition
 *
 *
 */
var isValue = function (value) {
    var isRealValue = ![undefined, null].includes(value);
    return isRealValue;
};
export default isValue;
