import React from 'react';
import rootStore from '../../store/createStore';
import { injectReducer } from '../../store/middleware/rootReducer';
import { injectSaga } from '../../store/middleware/rootSaga';
import reducer from './modules/reducers';
import saga from './modules/sagas';
// import LoaderSync from '../../helpers/LoaderSync';
import LoaderAsync from '../../helpers/LoaderAsync';
import isServer from '../../utils/isServer';
import Preloader from './components/Preloader';
var Skeleton = function () { return React.createElement(Preloader, null); };
var HomeRoute = function () {
    if (!isServer()) {
        injectReducer(rootStore.store, { reducer: reducer, key: 'home' }, rootStore.history);
        injectSaga({ key: 'home', saga: saga });
    }
    // const Loader = !__SSR__ ? LoaderAsync : LoaderSync;
    return React.createElement(LoaderAsync, { routeName: 'Home', preloader: Skeleton });
};
export default HomeRoute;
