/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from '../Link';
import styles from './index.scss';
var Logo = function () {
    return (React.createElement("div", { className: styles.logoWrap },
        React.createElement(Link, { to: '/' },
            React.createElement("img", { className: styles.logo, src: '/assets/logo.png', alt: 'logo' }))));
};
export default Logo;
