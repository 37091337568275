import { PROFILE_ATTEMPT, PROFILE_SAVED, PROFILE_ERROR, PROFILE_CHANGE_ATTEMPT, PROFILE_CHANGE_SAVED, PROFILE_CHANGE_ERROR, CHANGE_PROFILE_DATA, ERASE_PROFILE } from '../constants';
export var loadProfileDataAttempt = function () { return ({
    type: PROFILE_ATTEMPT
}); };
export var loadProfileDataSaved = function (username, email, mobile, bio, usersurname, imageurl, searchHistory) { return ({
    username: username,
    email: email,
    mobile: mobile,
    bio: bio,
    usersurname: usersurname,
    imageurl: imageurl,
    searchHistory: searchHistory,
    type: PROFILE_SAVED
}); };
export var loadProfileDataError = function (error) { return ({
    error: error,
    type: PROFILE_ERROR
}); };
export var saveNewDataAttempt = function () { return ({
    type: PROFILE_CHANGE_ATTEMPT
}); };
export var saveNewDataSaved = function (emailID) { return ({
    emailID: emailID,
    type: PROFILE_CHANGE_SAVED
}); };
export var saveNewDataError = function (error) { return ({
    error: error,
    type: PROFILE_CHANGE_ERROR
}); };
export var changeProfileData = function (id, value) { return ({
    id: id,
    value: value,
    type: CHANGE_PROFILE_DATA
}); };
export var eraseProfileData = function () { return ({
    type: ERASE_PROFILE
}); };
