var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { USER_SOCIAL_DATA, LOGIN_ATTEMPT, LOGIN_SAVED, LOGIN_ERROR, LOGIN_INPUT } from '../constants';
export var loadLoginDataAttempt = function () { return ({
    type: LOGIN_ATTEMPT
}); };
export var setSocialData = function (userData) { return (__assign(__assign({}, userData), { type: USER_SOCIAL_DATA })); };
export var loadLoginDataSaved = function () { return ({
    type: LOGIN_SAVED
}); };
export var loadLoginDataError = function (error) { return ({
    error: error,
    type: LOGIN_ERROR
}); };
export var setLoginInput = function (_a) {
    var id = _a.id, value = _a.value;
    return ({
        id: id,
        value: value,
        type: LOGIN_INPUT
    });
};
