import React from 'react';
import { GApiProvider } from 'react-gapi-auth2';
import Logout from './Logout';
import { OATH_CONFIG } from './constants';
var OathWrapper = function (_a) {
    var children = _a.children;
    return (React.createElement(GApiProvider, { clientConfig: OATH_CONFIG },
        React.createElement(Logout, null),
        children));
};
export default OathWrapper;
