// The easing function that makes the scroll decelerate over time
function easeOutQuart(x) {
    return 1 - Math.pow((1 - x), 4);
}
export function animateScroll(_a) {
    var targetPosition = _a.targetPosition, initialPosition = _a.initialPosition, duration = _a.duration;
    var start;
    var position;
    var animationFrame;
    var requestAnimationFrame = window.requestAnimationFrame;
    var cancelAnimationFrame = window.cancelAnimationFrame;
    // maximum amount of pixels we can scroll
    var maxAvailableScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    var amountOfPixelsToScroll = initialPosition - targetPosition;
    function step(timestamp) {
        if (start === undefined) {
            start = timestamp;
        }
        var elapsed = timestamp - start;
        // this just gives us a number between 0 (start) and 1 (end)
        var relativeProgress = elapsed / duration;
        // ease out that number
        var easedProgress = easeOutQuart(relativeProgress);
        // calculate new position for every thick of the requesAnimationFrame
        position = initialPosition - amountOfPixelsToScroll * Math.min(easedProgress, 1);
        // set the scrollbar position
        window.scrollTo(0, position);
        // Stop when max scroll is reached
        if (initialPosition !== maxAvailableScroll && window.scrollY === maxAvailableScroll) {
            cancelAnimationFrame(animationFrame);
            return;
        }
        // repeat until the end is reached
        if (elapsed < duration) {
            animationFrame = requestAnimationFrame(step);
        }
    }
    animationFrame = requestAnimationFrame(step);
}
