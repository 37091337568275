var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable */
import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import classNames from 'classnames';
import LoadIndicator from '../LoadIndicator';
import keyDownHandler, { KEY_TYPES } from '../../utils/keyDownHandler';
import { newsletterSubscriptionAttempt } from '../../controller/actions';
import styles from './index.scss';
var INVALID_EMAIL_ERROR = 'Email should be valid (e.g. xxx@xxx.xxx)';
var EMPTY_EMAIL = 'Email should not be empty';
var ALREADY_SUBSCRIBED = 'Email already subscribed';
var SubscribeEmail = memo(function (_a) {
    var _b;
    var userEmail = _a.userEmail, subscribeNewsletterAttempt = _a.subscribeNewsletterAttempt, isSubscribeNewsletterInProgress = _a.isSubscribeNewsletterInProgress;
    var _c = __read(useState(''), 2), inputValue = _c[0], setValue = _c[1];
    var _d = __read(useState(false), 2), isInvalidEmail = _d[0], setInvalidEmailError = _d[1];
    var _e = __read(useState(false), 2), isEmptyEmail = _e[0], setEmptyEmailError = _e[1];
    var _f = __read(useState(false), 2), isAlreadySubscribed = _f[0], setAlreadySubscribedEmailError = _f[1];
    var handleChange = function (_a) {
        var target = _a.target;
        setValue(target.value);
    };
    var handleInvalidEmail = function (status) {
        setInvalidEmailError(status);
    };
    var handleEmptyEmail = function (status) {
        setEmptyEmailError(status);
    };
    var handleAlreadySubscribedEmail = function (status) {
        setAlreadySubscribedEmailError(status);
    };
    var handleClear = function () {
        setValue('');
    };
    var handleSave = function () {
        var isEmailValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(inputValue);
        if (!inputValue || inputValue.length === 0) {
            handleEmptyEmail(true);
            handleInvalidEmail(false);
            return;
        }
        if (!isEmailValid) {
            handleEmptyEmail(false);
            handleInvalidEmail(true);
            return;
        }
        if (userEmail === inputValue) {
            handleAlreadySubscribedEmail(true);
            handleEmptyEmail(false);
            handleInvalidEmail(false);
            return;
        }
        if (!inputValue || isSubscribeNewsletterInProgress) {
            return;
        }
        subscribeNewsletterAttempt(inputValue);
    };
    var handleKeyDownChange = function (e) {
        keyDownHandler({
            event: e,
            keysConfig: [
                {
                    type: KEY_TYPES.esc,
                    onEvent: handleClear
                },
                {
                    type: KEY_TYPES.enter,
                    onEvent: handleSave
                }
            ]
        });
    };
    var handleClickBlur = function () {
        handleEmptyEmail(false);
        handleInvalidEmail(false);
        handleAlreadySubscribedEmail(false);
    };
    var btnClasses = classNames((_b = {},
        _b[styles.emailSendButton] = true,
        _b[styles.emailFilled] = inputValue || !isSubscribeNewsletterInProgress,
        _b));
    var renderEmailError = function () {
        var errorMessage = null;
        if (isEmptyEmail) {
            errorMessage = EMPTY_EMAIL;
        }
        if (isInvalidEmail) {
            errorMessage = INVALID_EMAIL_ERROR;
        }
        if (isAlreadySubscribed) {
            errorMessage = ALREADY_SUBSCRIBED;
        }
        return ((isInvalidEmail || isEmptyEmail || isAlreadySubscribed) && (React.createElement("span", { className: styles.emailError }, errorMessage)));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { className: styles.title }, i18n.t('footer.blocks.third_block.title')),
        React.createElement("div", { className: styles.newsletter },
            React.createElement("input", { onChange: function (e) { return handleChange(e); }, onKeyDown: function (e) { return handleKeyDownChange(e); }, id: 'newsletterInput', type: 'email', name: 'email', className: styles.input, value: inputValue, placeholder: 'E-mail' }),
            renderEmailError(),
            React.createElement("label", { htmlFor: 'newsletterInput', className: styles.labelInput }),
            React.createElement("button", { onClick: function () { return handleSave(); }, onBlur: function () { return handleClickBlur(); }, type: 'button', className: btnClasses, disabled: isSubscribeNewsletterInProgress }, isSubscribeNewsletterInProgress ? (React.createElement(LoadIndicator, { color: 'white', type: 'flat' })) : (i18n.t('footer.blocks.third_block.content.button'))))));
});
SubscribeEmail.displayName = 'SubscribeEmail';
var mapStateToProps = function (state) {
    var _a, _b, _c, _d, _e;
    return ({
        isSubscribeNewsletterInProgress: (_b = (_a = state.app) === null || _a === void 0 ? void 0 : _a.subscribeNewsletter) === null || _b === void 0 ? void 0 : _b.isInProgress,
        userEmail: (_d = (_c = state.app) === null || _c === void 0 ? void 0 : _c.subscribeNewsletter) === null || _d === void 0 ? void 0 : _d.userEmail,
        locale: (_e = state.app) === null || _e === void 0 ? void 0 : _e.locale
    });
};
var mapDispatchToState = {
    subscribeNewsletterAttempt: newsletterSubscriptionAttempt
};
export default connect(mapStateToProps, mapDispatchToState)(SubscribeEmail);
