import { LOGOUT_ATTEMPT, LOGOUT_SAVED, LOGOUT_ERROR, MANUAL_LOGOUT } from '../constants';
export var loadLogoutDataAttempt = function () { return ({
    type: LOGOUT_ATTEMPT
}); };
export var loadLogoutDataSaved = function (error) { return ({
    error: error,
    type: LOGOUT_SAVED
}); };
export var loadLogoutDataError = function (error) { return ({
    error: error,
    type: LOGOUT_ERROR
}); };
export var setManualLogout = function (status) { return ({
    status: status,
    type: MANUAL_LOGOUT
}); };
