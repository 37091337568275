var initialState = {
    username: '',
    usersurname: '',
    imageurl: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    isRegistrationFetch: false,
    isSocialRegistration: false,
    error: null
};
export default initialState;
