import { WS_SEND_MESSAGE } from '../../../constants';
import { CAR_ATTEMPT, CAR_ERROR, CAR_SAVED } from '../constants';
export var loadCarDataAttempt = function (payload) { return ({
    carNumber: payload,
    type: CAR_ATTEMPT
}); };
export var loadCarDataSaved = function (payload) { return ({
    payload: payload,
    type: CAR_SAVED
}); };
export var loadCarDataError = function () { return ({
    type: CAR_ERROR
}); };
export var wsSendMessage = function (payload) { return ({
    payload: payload,
    type: WS_SEND_MESSAGE
}); };
