var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable max-statements */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import { setLocale } from '../../controller/actions';
import changeMetaData from '../../utils/changeMetaData';
import styles from './index.scss';
import { BUTTONS_LANGUAGES } from './constants';
import { LOCALES_ADAPTER } from '../../constants/locals';
import langPathChanger from '../../controller/helpers/langPathChanges';
var Language = function () {
    var dispatch = useDispatch();
    var _a = __read(useState(false), 2), isOpen = _a[0], toggleDropDown = _a[1];
    var _b = __read(useState(null), 2), currentLocale = _b[0], setLanguage = _b[1];
    var pathRewriter = function (newPath) {
        var linkTo = newPath[0] === '/' ? newPath : "/".concat(newPath);
        var newLocale = newPath.substr(0, 2);
        dispatch(push(linkTo));
        dispatch(setLocale(['en', 'ru', 'ua'].includes(newLocale) ? newLocale : '/'));
        changeMetaData();
    };
    useEffect(function () {
        var selectedLangLabel = LOCALES_ADAPTER[window.navigator.language] || LOCALES_ADAPTER.en;
        setLanguage({
            label: selectedLangLabel,
            icon: BUTTONS_LANGUAGES.find(function (_a) {
                var label = _a.label;
                return label === selectedLangLabel;
            }).icon
        });
        i18next.changeLanguage(selectedLangLabel);
        langPathChanger({ label: selectedLangLabel, callback: pathRewriter });
    }, []);
    var handleLanguageDropDown = function (type) { return toggleDropDown(type === 'close' ? false : !isOpen); };
    // FIXME: make this part of the code less complex!
    var handleLanguageSet = function (_a) {
        var target = _a.target;
        if (!isOpen) {
            return;
        }
        i18next.changeLanguage(target.dataset.label);
        handleLanguageDropDown('close');
        setLanguage({
            label: target.dataset.label,
            icon: target.dataset.icon
        });
        langPathChanger({ label: target.dataset.label, callback: pathRewriter });
    };
    var renderButton = function (btnProps) {
        if (btnProps === void 0) { btnProps = {}; }
        // @ts-ignore
        var _a = btnProps || {}, label = _a.label, icon = _a.icon;
        if (!label || !icon) {
            return null;
        }
        return (React.createElement("button", { key: label, type: 'button', "data-label": label, "data-icon": icon, "aria-label": label, className: "".concat(styles.flag, " ").concat(styles[icon]), onMouseDown: function (e) { return handleLanguageSet(e); } }));
    };
    var selectedOption = renderButton(currentLocale);
    var optionsAvailable = BUTTONS_LANGUAGES.map(function (_a) {
        var label = _a.label, icon = _a.icon;
        if (!isOpen || !label || label === currentLocale.label) {
            return null;
        }
        return renderButton({ label: label, icon: icon });
    });
    return (React.createElement("div", { className: styles.langContainer },
        React.createElement("div", { role: 'button', tabIndex: 0, "arial-label": 'Select Language', className: "".concat(styles.language, " ").concat(isOpen ? styles.isOpen : ''), onClick: function () { return handleLanguageDropDown(); }, onBlur: function () { return handleLanguageDropDown('close'); }, onKeyDown: undefined },
            selectedOption,
            optionsAvailable)));
};
export default Language;
