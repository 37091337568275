var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
// some reducers here. The same link in on top level
import initialState from './initialState';
import { PROFILE_ATTEMPT, PROFILE_SAVED, PROFILE_ERROR, PROFILE_CHANGE_ATTEMPT, PROFILE_CHANGE_ERROR, PROFILE_CHANGE_SAVED, CHANGE_PROFILE_DATA, ERASE_PROFILE } from '../constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
// you need to fill all of your reducers cases inside this object like an arrow functions
var ACTION_HANDLERS = (_a = {},
    _a[PROFILE_ATTEMPT] = function (state) { return (__assign(__assign({}, state), { isProfileFetch: true })); },
    _a[PROFILE_SAVED] = function (state, action) { return (__assign(__assign({}, state), { username: action.username, email: action.email, id: action.email, mobile: action.mobile, bio: action.bio, usersurname: action.usersurname, imageurl: action.imageurl, searchHistory: action.searchHistory, isProfileFetch: false })); },
    _a[PROFILE_ERROR] = function (state, action) { return (__assign(__assign({}, state), { isProfileFetch: false, error: action.error })); },
    _a[PROFILE_CHANGE_ATTEMPT] = function (state) { return (__assign(__assign({}, state), { isProfileChangeFetch: true })); },
    _a[PROFILE_CHANGE_SAVED] = function (state, action) { return (__assign(__assign({}, state), { id: action.emailID, password: '', newPassword: '', newPasswordConfirm: '', isProfileChangeFetch: false })); },
    _a[PROFILE_CHANGE_ERROR] = function (state, action) { return (__assign(__assign({}, state), { isProfileFetch: false, error: action.error })); },
    _a[CHANGE_PROFILE_DATA] = function (state, action) {
        var _a;
        return (__assign(__assign({}, state), (_a = {}, _a[action.id] = action.value, _a)));
    },
    _a[ERASE_PROFILE] = function (state) { return (__assign(__assign({}, state), { username: '', email: '', id: '', mobile: '', bio: '', usersurname: '', searchHistory: '', isProfileFetch: false, error: null })); },
    _a);
// ------------------------------------
// Reducer
// ------------------------------------
// you need to place here right typization and initialState bind
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default reducer;
