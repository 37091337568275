var scrollToEnd = function (_a) {
    var _b = _a === void 0 ? {} : _a, isSmooth = _b.isSmooth, withDelay = _b.withDelay, isPreventSmartScroll = _b.isPreventSmartScroll;
    var elem = document.querySelector('#messages');
    var isSmallOffset = elem.scrollHeight - elem.scrollTop < 500;
    if (isPreventSmartScroll && !isSmallOffset) {
        return;
    }
    var scroll = function () {
        return elem.scroll({
            top: 100000,
            behavior: isSmooth || isSmallOffset ? 'smooth' : 'auto'
        });
    };
    if (withDelay) {
        setTimeout(function () { return scroll(); }, 100);
        return;
    }
    scroll();
};
export default scrollToEnd;
