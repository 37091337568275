var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import scrollTo from '../../utils/smoothScroll';
import styles from './index.scss';
var HEIGHT_TO_HIDE = 200;
var ScrollToTop = function () {
    var _a = __read(useState(false), 2), isAppear = _a[0], setAppear = _a[1];
    useEffect(function () {
        var handleAppear = function () {
            setAppear(window.pageYOffset > HEIGHT_TO_HIDE);
        };
        window.addEventListener('scroll', handleAppear);
        window.addEventListener('resize', handleAppear);
        return function () {
            window.removeEventListener('scroll', handleAppear);
            window.removeEventListener('resize', handleAppear);
        };
    }, []);
    var handleScrollTop = function () {
        scrollTo({ id: 'header', duration: 1000 });
    };
    return (React.createElement("div", { className: "".concat(styles.scrollToTopWrap, " ").concat(isAppear ? styles.btnAppear : '') },
        React.createElement("button", { className: styles.buttonScrollTop, type: 'button', "aria-label": 'scroll to top', onClick: function () { return handleScrollTop(); } })));
};
export default ScrollToTop;
