import React from 'react';
import styles from './index.scss';
var Preloader = function () {
    return (React.createElement("div", { className: styles.preloader },
        React.createElement("img", { className: styles.logo, src: '/assets/logo.png', alt: 'logo' }),
        React.createElement("div", { className: styles.preloaderItem },
            React.createElement("div", null),
            React.createElement("div", null),
            React.createElement("div", null))));
};
export default Preloader;
