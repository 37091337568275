var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import isValue from '../isValue';
import isArray from '../isArray';
import isObject from '../isObject';
var PATH = '/';
var DOMAIN = (__DEV__ && 'localhost') || '.carradar.com.ua';
/**
 *  @name LocalStorageManager
 *  @author info@carradar.com.ua
 *  @version 1.0.0
 *  @description suitable way for managing localStorageData
 *
 *  @params {object} config - configuration of the watcher
 *  @params {string} config.storeKeys - a main key for bind with its data inside localStorage
 *  @params {string|number|boolean|object} config.storeData - a main config for localStorage set
 *
 *  @property {_state} object - main util state.
 *  @property {_state.useCookieStore} object - main util state.
 *
 *  @returns {constructor}
 *
 */
var LocalStorageManager = /** @class */ (function () {
    function LocalStorageManager() {
        var _this = this;
        this._setState = function (newValue) {
            _this._state = __assign(__assign({}, _this._state), newValue);
        };
        this._isComplexityStore = function (storeData) { return isArray(storeData) || isObject(storeData); };
        this._isLocalStorageAvailable = function () {
            try {
                var localStorage_1 = window.localStorage;
                var test_1 = '__isLocalStorageAvailable__';
                localStorage_1.setItem(test_1, test_1);
                localStorage_1.removeItem(test_1);
                return true;
            }
            catch (e) {
                return false;
            }
        };
        this._setInitPayload = function (isLocalStorageAvailable, storeKey) {
            var cookieData = _this._getCookie(storeKey);
            var localStorageData = localStorage.getItem(storeKey);
            if (isLocalStorageAvailable && !_this._state.useCookieStore && isValue(cookieData)) {
                localStorage.setItem(storeKey, cookieData);
                _this._deleteCookie(storeKey);
            }
            else if ((!isLocalStorageAvailable || _this._state.useCookieStore) && isValue(localStorageData)) {
                _this._setCookie(storeKey, localStorageData);
                localStorage.removeItem(storeKey);
            }
        };
        this._getCookie = function (cookieKey) {
            var cookieRaw = document.cookie.split('; ');
            var cookieKeyValuePair = cookieRaw.find(function (KeyValue) { return KeyValue.startsWith(cookieKey); });
            var cookieValue = cookieKeyValuePair ? cookieKeyValuePair.split('=')[1] : null;
            return cookieValue;
        };
        this._setCookie = function (storeKey, storeData) {
            document.cookie = "".concat(storeKey, "=").concat(storeData, ";path=").concat(PATH, ";domain=").concat(DOMAIN, ";expires=Fri, 31 Dec 9999 23:59:59 GMT\";");
        };
        this._normalizeStoreData = function (storeData) {
            var normalizedStoreData = _this._isComplexityStore(storeData) ? JSON.stringify(storeData) : String(storeData);
            return normalizedStoreData;
        };
        this._setStore = function (_a) {
            var storeKey = _a.storeKey, storeData = _a.storeData;
            try {
                var normalizedStoreData = _this._normalizeStoreData(storeData);
                if (_this._isLocalStorageAvailable() && !_this._state.useCookieStore) {
                    var localStorage_2 = window.localStorage;
                    localStorage_2.setItem(storeKey, normalizedStoreData);
                }
                else {
                    _this._setCookie(storeKey, normalizedStoreData);
                }
            }
            catch (e) {
                throw new Error("Some error during localStorage data set: ".concat(e));
            }
        };
        /**
         * Returns a boolean indicating whether an data with the specified key exists or not.
         * @name LocalStorageManager#hasKey
         * @function
         *
         */
        this.hasKey = function (_a) {
            var storeKey = _a.storeKey;
            if (!storeKey) {
                console.error("Store key was missed: ".concat(storeKey));
                return null;
            }
            var currentStore = null;
            if (_this._isLocalStorageAvailable() && !_this._state.useCookieStore) {
                var localStorage_3 = window.localStorage;
                currentStore = localStorage_3.getItem(storeKey);
            }
            else {
                currentStore = _this._getCookie(storeKey);
            }
            return isValue(currentStore);
        };
        /**
         * Allow us to set data by key inside localStorage.
         * @name LocalStorageManager#addStore
         * @function
         *
         */
        this.addStore = function (_a) {
            var storeKey = _a.storeKey, storeData = _a.storeData;
            if (!isValue(storeData) || !storeKey) {
                console.error('Some data missed among your initialization config: ', storeKey, storeData);
                return;
            }
            _this._setStore({ storeKey: storeKey, storeData: storeData });
            return _this.getStore({ storeKey: storeKey });
        };
        /**
         * Returns an object with data inside particular key from localStore.
         * @name LocalStorageManager#getStore
         * @function
         *
         * @readonly
         */
        this.updateStore = function (_a) {
            var storeKey = _a.storeKey, storeData = _a.storeData;
            if (!storeKey || !isValue(storeData)) {
                console.error("Something wrong with init data: ".concat(storeKey, ", ").concat(storeData));
                return null;
            }
            _this._setStore({ storeKey: storeKey, storeData: storeData });
            return _this.getStore({ storeKey: storeKey });
        };
        /**
         * Returns an object with data inside particular key from localStore.
         * @name LocalStorageManager#getStore
         * @function
         *
         * @readonly
         */
        this.getStore = function (_a) {
            var storeKey = _a.storeKey;
            if (!storeKey) {
                console.error("Store key was missed: ".concat(storeKey));
                return null;
            }
            var currentStore = null;
            if (_this._isLocalStorageAvailable() && !_this._state.useCookieStore) {
                var localStorage_4 = window.localStorage;
                currentStore = localStorage_4.getItem(storeKey);
            }
            else {
                currentStore = _this._getCookie(storeKey);
            }
            try {
                return JSON.parse(currentStore);
            }
            catch (_b) {
                return currentStore;
            }
        };
        /**
         * Allow delete some key-value pair from localStore.
         * @name LocalStorageManager#checkString
         * @function
         *
         */
        this.removeStore = function (_a) {
            var storeKey = _a.storeKey;
            try {
                if (_this._isLocalStorageAvailable() && !_this._state.useCookieStore) {
                    var localStorage_5 = window.localStorage;
                    localStorage_5.removeItem(storeKey);
                }
                else {
                    _this._deleteCookie(storeKey);
                }
            }
            catch (e) {
                throw new Error("Some problem occurred during storage removing, ".concat(e));
            }
        };
        /**
         * Allows to set new data in some particular store by key.
         * @name LocalStorageManager#addToStore
         * @function
         *
         */
        this.addToStore = function (_a) {
            var storeKey = _a.storeKey, storeData = _a.storeData;
            if (!isValue(storeData) || !storeKey) {
                console.error('Store was not updated! Error in config: ', storeKey, storeData);
                return null;
            }
            var prevStore = _this.getStore({ storeKey: storeKey });
            var newData = (isArray(prevStore) && __spreadArray(__spreadArray([], __read(prevStore), false), [storeData], false)) || (isObject(prevStore) && __assign(__assign({}, prevStore), storeData));
            _this._setStore({
                storeKey: storeKey,
                storeData: newData
            });
            return _this.getStore({ storeKey: storeKey });
        };
        /**
         * Allows to delete some data from the store by key.
         * @name LocalStorageManager#deleteFromStore
         * @function
         *
         */
        this.removeFromStore = function (_a) {
            var storeKey = _a.storeKey, valID = _a.valID, valMatch = _a.valMatch;
            var prevStore = _this.getStore({ storeKey: storeKey });
            var filterArrayData = function () {
                if (valID) {
                    return __spreadArray([], __read(prevStore.filter(function (unit) { return unit[valID] !== valMatch; })), false);
                }
                return __spreadArray([], __read(prevStore.filter(function (unit) { return unit !== valMatch; })), false);
            };
            var filterObjectData = function () {
                var newObjData = {};
                Object.keys(prevStore).forEach(function (key) {
                    if (key === valID && prevStore[valID] === valMatch) {
                        return;
                    }
                    newObjData[key] = prevStore[key];
                });
                return newObjData;
            };
            _this._setStore({
                storeKey: storeKey,
                storeData: (isArray(prevStore) && filterArrayData()) || (isObject(prevStore) && filterObjectData())
            });
            return _this.getStore({ storeKey: storeKey });
        };
        this.init = function (props) {
            var storeKey = props.storeKey, useCookieStore = props.useCookieStore;
            var isLocalStorageAvailable = _this._isLocalStorageAvailable();
            _this._setState({
                useCookieStore: useCookieStore,
                isLocalStorageAvailable: isLocalStorageAvailable
            });
            // in case we have some data stored
            // before initialization stage in cookie/localStorage
            _this._setInitPayload(isLocalStorageAvailable, storeKey);
        };
        this._state = {
            useCookieStore: false,
            isLocalStorageAvailable: false
        };
    }
    LocalStorageManager.prototype._deleteCookie = function (cookieKey) {
        if (!this._getCookie(cookieKey)) {
            return;
        }
        document.cookie = "".concat(cookieKey, "=\"\";path=").concat(PATH, ";domain=").concat(DOMAIN, ";expires=Thu, 01 Jan 1970 00:00:01 GMT\";");
    };
    return LocalStorageManager;
}());
export default LocalStorageManager;
/**
 * @deprecated
 *
 */
export var hasKey = (_a = new LocalStorageManager(), _a.hasKey), addStore = _a.addStore, updateStore = _a.updateStore, getStore = _a.getStore, removeStore = _a.removeStore, removeFromStore = _a.removeFromStore, addToStore = _a.addToStore, init = _a.init;
