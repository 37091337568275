import changeMetaData from '../../utils/changeMetaData';
var langPathChanger = function (_a) {
    var label = _a.label, callback = _a.callback;
    if (['en', 'ru', 'ua'].includes(label)) {
        var prevPath = location.pathname;
        var isLanguageRoot = /^\/(en)|(ru)/i.test(location.pathname);
        var isCoreRoot = label === 'ua';
        var isLeadingSlash = function (str) { return /^\//i.test(str); };
        var pathWithoutPrevLanguage = isLanguageRoot
            ? prevPath.substr(3, prevPath.length)
            : prevPath.substr(1, prevPath.length);
        var newPath = "".concat(!isCoreRoot ? label : '').concat(isLeadingSlash(pathWithoutPrevLanguage) ? '' : '/').concat(pathWithoutPrevLanguage);
        var newPathOptimized = isCoreRoot && isLeadingSlash(newPath) ? newPath.substr(1, newPath.length) : newPath;
        var newNormalizedPath = newPathOptimized[newPathOptimized.length - 1] === '/' ? newPath.substr(0, newPath.length - 1) : newPath;
        callback(newNormalizedPath);
        changeMetaData();
    }
};
export default langPathChanger;
