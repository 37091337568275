var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import styles from './index.scss';
var Loader = /** @class */ (function (_super) {
    __extends(Loader, _super);
    function Loader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._addChildren = function (_a) {
            var count = _a.count;
            var _b = _this.props.color, color = _b === void 0 ? 'grey' : _b;
            return Array.from(Array(count).keys()).map(function (key) { return React.createElement("div", { key: key, className: styles[color] }); });
        };
        _this._flatLoader = function () {
            return React.createElement("div", { className: styles.flatLoader }, _this._addChildren({ count: 4 }));
        };
        _this._circleLoader = function () {
            return React.createElement("div", { className: styles.spinnerCircle }, _this._addChildren({ count: 12 }));
        };
        return _this;
    }
    Loader.prototype.render = function () {
        var type = this.props.type;
        return type === 'circle' ? this._circleLoader() : this._flatLoader();
    };
    return Loader;
}(React.PureComponent));
export default Loader;
