import { useEffect } from 'react';
var useGlobalClose = function (isOpen, onClickClose) {
    var _handleGlobalCloseClick = function (e) {
        if (e.target.id === 'dark_mode_toggler') {
            return;
        }
        if (isOpen && document.getElementById('sidebarBlock').contains(e.target)) {
            onClickClose();
        }
    };
    useEffect(function () {
        window.addEventListener('click', _handleGlobalCloseClick);
        return function () {
            window.removeEventListener('click', _handleGlobalCloseClick);
        };
    }, [isOpen]);
};
export default useGlobalClose;
