var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createResponsiveStateReducer } from 'redux-responsive';
import app from '../../controller/reducers/index';
import home from '../../routes/Home/modules/reducers';
import isServer from '../../utils/isServer';
var makeRootReducer = function (asyncReducers, history) {
    return combineReducers(__assign(__assign(__assign(__assign({}, asyncReducers), { app: app }), (isServer()
        ? {
            home: home
        }
        : {})), { router: connectRouter(history), browser: createResponsiveStateReducer({
            mobile: 600,
            tablet: 1000,
            desktop: 5000
        }) }));
};
export var injectReducer = function (store, _a, history) {
    var key = _a.key, reducer = _a.reducer;
    if (Object.hasOwnProperty.call(store.asyncReducers, key))
        return;
    store.asyncReducers[key] = reducer;
    store.replaceReducer(makeRootReducer(store.asyncReducers, history));
};
export default makeRootReducer;
