var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { TOGGLE_CHAT, SENT_MESSAGE, RECEIVE_MESSAGE, WRITTING_MESSAGE, CONNECTION_ERROR, CLEAR_ERROR, SET_NEW_MESSAGES_COUNT } from '../../constants';
import initialState from '../initialState';
import setErrorMessage from '../../utils/setErrorMessage';
// ------------------------------------
// Action Handlers
// ------------------------------------
var ACTION_HANDLERS = (_a = {},
    _a[TOGGLE_CHAT] = function (state) { return (__assign(__assign({}, state), { showChat: !state.showChat })); },
    _a[WRITTING_MESSAGE] = function (state, action) { return (__assign(__assign({}, state), { currentMessage: action.message })); },
    _a[SENT_MESSAGE] = function (state, action) { return (__assign(__assign({}, state), { isPendingRequest: true, currentMessage: '', messages: __spreadArray(__spreadArray([], __read(state.messages), false), [
            {
                text: action.text,
                timestamp: action.timestamp,
                type: 'user'
            }
        ], false) })); },
    _a[RECEIVE_MESSAGE] = function (state, action) { return (__assign(__assign({}, state), { isPendingRequest: false, messages: __spreadArray(__spreadArray([], __read(state.messages), false), [
            {
                text: action.text,
                timestamp: action.timestamp,
                type: 'agent'
            }
        ], false) })); },
    _a[CONNECTION_ERROR] = function (state) { return (__assign(__assign({}, state), { isPendingRequest: false, isConnectionError: true, messages: setErrorMessage(state.messages) })); },
    _a[CLEAR_ERROR] = function (state) { return (__assign(__assign({}, state), { isConnectionError: false })); },
    _a[SET_NEW_MESSAGES_COUNT] = function (state, action) { return (__assign(__assign({}, state), { newMessagesCount: action.count })); },
    _a);
// ------------------------------------
// Reducer
// ------------------------------------
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default reducer;
