import { NEWSLETTER_ATTEMPT, NEWSLETTER_ERROR, NEWSLETTER_SAVED, REQUEST_NOTIFY_ERROR, REQUEST_NOTIFY_SUCCESS, REQUEST_NOTIFY_CLEAR, SET_LOCALE, SET_LOGGED, TOGGLE_DARK_MODE } from '../../constants';
export var requestError = function (payload) { return ({
    type: REQUEST_NOTIFY_ERROR,
    errorMessage: payload
}); };
export var requestSuccess = function (payload) { return ({
    type: REQUEST_NOTIFY_SUCCESS,
    successMessage: payload
}); };
export var requestNotifyClear = function () { return ({
    type: REQUEST_NOTIFY_CLEAR
}); };
export var setLocale = function (payload) { return ({
    language: payload,
    type: SET_LOCALE
}); };
export var setLogged = function (isLogged) { return ({
    isLogged: isLogged,
    type: SET_LOGGED
}); };
export var newsletterSubscriptionAttempt = function (payload) { return ({
    userEmail: payload,
    type: NEWSLETTER_ATTEMPT
}); };
export var newsletterSubscriptionSaved = function (payload) { return ({
    error: payload.error,
    success: payload.success,
    type: NEWSLETTER_SAVED
}); };
export var newsletterSubscribeError = function (payload) { return ({
    error: payload,
    type: NEWSLETTER_ERROR
}); };
export var toggleDarkMode = function () { return ({
    type: TOGGLE_DARK_MODE
}); };
