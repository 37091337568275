import makeRootReducer from '../middleware/rootReducer';
var activateStoreHMR = function (store) {
    // Hot Module Replacement for Controller/Store
    if (module.hot) {
        module.hot.accept('../middleware/rootReducer', function () {
            store.replaceReducer(makeRootReducer(store.asyncReducers));
        });
    }
};
export default activateStoreHMR;
