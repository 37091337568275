import { getStore } from './helpers/localStorage';
var initialState = {
    showChat: false,
    isOpen: false,
    isPendingRequest: false,
    isConnectionError: false,
    newMessagesCount: 0,
    currentMessage: getStore({ storeKey: 'chat-current-input' }),
    messages: getStore({ storeKey: 'chat-data' })
};
export default initialState;
