import { useEffect } from 'react';
import { useGoogleAuth } from 'react-gapi-auth2';
import isLogged from '../../utils/isLogged';
var Logout = function () {
    var googleAuth = useGoogleAuth().googleAuth;
    useEffect(function () {
        if (isLogged()) {
            googleAuth && googleAuth.signOut();
        }
    }, []);
    return null;
};
export default Logout;
