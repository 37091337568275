import React, { memo } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import SubscribeEmail from './SubscribeEmail';
import Link from '../Link';
import styles from './index.scss';
var Footer = memo(function () {
    return (React.createElement("div", { className: styles.footer },
        React.createElement("div", { className: styles.footerContent },
            React.createElement("div", { className: styles.companyDescription },
                React.createElement("img", { src: '/assets/logo.png', alt: 'logo', className: styles.companyLogo }),
                React.createElement("p", { className: styles.text },
                    "CarRadar(c) - ",
                    i18n.t('footer.main_text'))),
            React.createElement("div", { className: styles.bullets },
                React.createElement("div", { className: styles.contentBlock },
                    React.createElement("span", { className: styles.title }, i18n.t('footer.blocks.first_block.title')),
                    React.createElement("address", { className: styles.address },
                        React.createElement("span", { className: styles.text }, i18n.t('footer.blocks.first_block.content.line_1')),
                        React.createElement("br", null),
                        React.createElement("span", { className: styles.text }, i18n.t('footer.blocks.first_block.content.line_2')),
                        React.createElement("br", null),
                        React.createElement("span", { className: styles.text }, i18n.t('footer.blocks.first_block.content.line_3')),
                        React.createElement("br", null),
                        React.createElement("span", { className: styles.text }, i18n.t('footer.blocks.first_block.content.line_4')))),
                React.createElement("div", { className: styles.contentBlock },
                    React.createElement("span", { className: styles.title }, i18n.t('footer.blocks.second_block.title')),
                    React.createElement("div", { className: styles.tel },
                        React.createElement("i", { className: styles.iconTel }),
                        React.createElement("a", { className: styles.text, href: 'tel:#' }, "+38 (050) 824-87-25")),
                    React.createElement("div", { className: styles.email },
                        React.createElement("i", { className: styles.iconEmail }),
                        React.createElement("a", { className: styles.text, href: 'mailto:#' }, "info@carradar.com")),
                    React.createElement("div", { className: styles.social },
                        React.createElement("a", { className: styles.iconTwitter, href: '/', "aria-label": 'twitter' }),
                        React.createElement("a", { className: styles.iconInstagram, href: '/', "aria-label": 'twitter' }),
                        React.createElement("a", { className: styles.iconFacebook, href: '/', "aria-label": 'facebook' }),
                        React.createElement("a", { className: styles.iconTelegram, href: '/', "aria-label": 'telegram' }))),
                React.createElement("div", { className: styles.contentBlock },
                    React.createElement(SubscribeEmail, null)))),
        React.createElement("div", { className: styles.copywriting },
            React.createElement("div", { className: styles.copywritingContent },
                React.createElement("div", { className: styles.rights },
                    React.createElement("span", { className: styles.text },
                        "\u00A9 2021 CarRadar(c). ",
                        i18n.t('footer.copyright'),
                        ". v.1.0.0")),
                React.createElement("div", { className: styles.links },
                    React.createElement(Link, { className: styles.link, to: '/about-us' },
                        React.createElement("span", { className: styles.text }, i18n.t('header.menu.about_us'))),
                    React.createElement(Link, { className: styles.link, to: '/contact' },
                        React.createElement("span", { className: styles.text }, i18n.t('header.menu.contact'))),
                    React.createElement(Link, { className: styles.link, to: '/sign-in' },
                        React.createElement("span", { className: styles.text }, i18n.t('header.menu.sign_in'))),
                    React.createElement(Link, { className: styles.link, to: '/sign-up' },
                        React.createElement("span", { className: styles.text }, i18n.t('header.menu.sign_up'))),
                    React.createElement(Link, { className: styles.link, to: '/policy' },
                        React.createElement("span", { className: styles.text }, i18n.t('footer.policy')))),
                React.createElement("div", { className: styles.policy },
                    React.createElement(Link, { className: styles.link, isNotSPA: true, to: 'https://linkedin.com/in/sviatoslav-kuzhelev-78797b104' },
                        React.createElement("span", { className: styles.text }, "by Sviatoslav Kuzhelev")))))));
});
Footer.displayName = 'Footer';
var mapStateToProps = function (state) { return ({
    locale: state.app.locale
}); };
export default connect(mapStateToProps, null)(Footer);
