import React, { memo } from 'react';
import i18n from 'i18next';
import { connect } from 'react-redux';
import Link from '../Link';
import styles from './index.scss';
var Links = function (_a) {
    var isLogged = _a.isLogged, isDesktop = _a.isDesktop;
    var UnloggedLinks = memo(function () { return (React.createElement(React.Fragment, null,
        React.createElement(Link, { className: styles.link, to: '/sign-in' },
            React.createElement("span", { className: styles.text }, i18n.t('header.menu.sign_in'))),
        React.createElement(Link, { className: styles.link, to: '/sign-up' },
            React.createElement("span", { className: styles.text }, i18n.t('header.menu.sign_up'))))); });
    var LoggedLinks = memo(function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(Link, { className: styles.link, to: '/profile' },
                React.createElement("span", { className: styles.text }, i18n.t('header.menu.profile'))),
            React.createElement(Link, { className: styles.link, to: '/sign-out' },
                React.createElement("span", { className: styles.text }, i18n.t('header.menu.sign_out')))));
    });
    return (React.createElement("div", { className: styles.navBar },
        !isDesktop && (React.createElement(Link, { className: styles.link, to: '/' },
            React.createElement("span", { className: styles.text }, i18n.t('header.menu.home')))),
        React.createElement(Link, { className: styles.link, to: '/about-us' },
            React.createElement("span", { className: styles.text }, i18n.t('header.menu.about_us'))),
        React.createElement(Link, { className: styles.link, to: '/contact' },
            React.createElement("span", { className: styles.text }, i18n.t('header.menu.contact'))),
        !isLogged ? React.createElement(UnloggedLinks, null) : React.createElement(LoggedLinks, null)));
};
var mapStateToProps = function (_a) {
    var app = _a.app, browser = _a.browser;
    return ({
        language: app.locale,
        isLogged: app.isLogged,
        isDesktop: browser.is.desktop
    });
};
export default connect(mapStateToProps, null)(Links);
