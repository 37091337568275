// @ts-nocheck
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { responsiveStoreEnhancer } from 'redux-responsive';
import history from './history';
import logger from './helpers/reduxLogger';
import activeStoreHMR from './helpers/storeHMR';
import sagaMiddleware, { runSaga, rootSaga } from './middleware/rootSaga';
import rootReducer from './middleware/rootReducer';
import isServer from '../utils/isServer';
import socketMiddleware from './middleware/rootWebsockets';
// creating the root store config
var rootStore = function (url) {
    var historyInit = history(url);
    // ======================================================
    // Middleware Configuration
    // ======================================================
    var middleware = [routerMiddleware(historyInit), sagaMiddleware, logger];
    var enhancers = [];
    if (typeof WebSocket !== 'undefined') {
        middleware.push(socketMiddleware);
    }
    // allow to use the redux browser plugin
    // if (true && window?.__REDUX_DEVTOOLS_EXTENSION__ && !isServer()) {
    //   enhancers.push(window?.__REDUX_DEVTOOLS_EXTENSION__());
    // }
    var initialState = !isServer() && (window === null || window === void 0 ? void 0 : window.__PRELOADED_STATE__) ? window === null || window === void 0 ? void 0 : window.__PRELOADED_STATE__ : {};
    // ======================================================
    // Store Instantiation
    // ======================================================
    var store = createStore(rootReducer(null, historyInit), initialState, compose.apply(void 0, __spreadArray([responsiveStoreEnhancer, applyMiddleware.apply(void 0, __spreadArray([], __read(middleware), false))], __read(enhancers), false)));
    store.asyncReducers = {};
    runSaga(rootSaga);
    activeStoreHMR(store);
    return {
        store: store,
        history: historyInit
    };
};
export default rootStore();
