export var KEY_CODES = {
    esc: 27,
    enter: 13,
    tab: 9,
    space: 32,
    up: 38,
    left: 37,
    right: 39,
    down: 40
};
export var KEY_TYPES = {
    enter: 'enter',
    esc: 'esc',
    tab: 'tab',
    space: 'space',
    up: 'up',
    left: 'left',
    right: 'right',
    down: 'down'
};
