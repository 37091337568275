/**
 *  @name isLogged
 *  @author sviat
 *  @version 1.1.0
 *  @description tiny util that returns current session user ID value.
 *
 *  @return {string} ID - founded user ID from cookie.
 *
 *  @readonly
 */
var isLogged = function () {
    var getCookie = function (name) {
        var matchPrefix = '(?:^|; )';
        var matchSuffix = '=([^;]*)';
        var replacePattern = name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1');
        var matches = typeof document !== 'undefined' &&
            document.cookie &&
            document.cookie.match(new RegExp("".concat(matchPrefix).concat(replacePattern).concat(matchSuffix)));
        return matches && matches[1] ? decodeURIComponent(matches[1]) : undefined;
    };
    return getCookie('isLogged') === 'true';
};
export default isLogged;
