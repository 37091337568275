import isLogged from '../utils/isLogged';
var initialState = {
    locale: 'ua',
    appName: 'CarRadar',
    isDarkMode: false,
    isLogged: isLogged(),
    error: null,
    success: null,
    subscribeNewsletter: {
        userEmail: null,
        isInProgress: false,
        error: null,
        success: null
    }
};
export default initialState;
