var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
// some reducers here. The same link in on top level
import initialState from './initialState';
import { LOGOUT_ATTEMPT, LOGOUT_SAVED, LOGOUT_ERROR, MANUAL_LOGOUT } from '../constants';
// ------------------------------------
// Action Handlers
// ------------------------------------
// you need to fill all of your reducers cases inside this object like an arrow functions
var ACTION_HANDLERS = (_a = {},
    _a[LOGOUT_ATTEMPT] = function (state) { return (__assign(__assign({}, state), { isLogoutFetch: true })); },
    _a[LOGOUT_SAVED] = function (state, action) { return (__assign(__assign({}, state), { isManualReload: true, isLogoutFetch: false, error: action.error || state.error })); },
    _a[LOGOUT_ERROR] = function (state, action) { return (__assign(__assign({}, state), { isLogoutFetch: true, error: action.error })); },
    _a[MANUAL_LOGOUT] = function (state, action) { return (__assign(__assign({}, state), { isManualReload: action.status })); },
    _a);
// ------------------------------------
// Reducer
// ------------------------------------
// you need to place here right typization and initialState bind
var reducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    var handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
};
export default reducer;
