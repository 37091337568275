// scrollTo.js
import { animateScroll } from './animateScroll';
var logError = function () { return console.error("Invalid element, are you sure you've provided element id or react ref?"); };
var getElementPosition = function (element) { return element.offsetTop; };
export var scrollTo = function (_a) {
    var id = _a.id, _b = _a.ref, ref = _b === void 0 ? null : _b, _c = _a.duration, duration = _c === void 0 ? 3000 : _c;
    // the position of the scroll bar before the user clicks the button
    var initialPosition = window.scrollY;
    // decide what type of reference that is
    // if neither ref or id is provided  set element to null
    var element = (ref && ref.current) || (id && document.getElementById(id)) || null;
    if (!element) {
        // log error if the reference passed is invalid
        logError();
        return;
    }
    animateScroll({
        targetPosition: getElementPosition(element),
        initialPosition: initialPosition,
        duration: duration
    });
};
