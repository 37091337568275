/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Links from '../Header/Links';
import DarkModeSwitcher from '../DarkModeSwitcher';
import useGlobalClose from './hooks/useGlobalClose';
import styles from './index.module.scss';
var ANIM_SPEED = 400;
var Sidebar = function (_a) {
    var onClickClose = _a.onClickClose, isOpen = _a.isOpen;
    useGlobalClose(isOpen, onClickClose);
    var _handleClose = function () {
        onClickClose(false);
    };
    return (React.createElement(CSSTransition, { in: isOpen, classNames: 'sidebar', timeout: ANIM_SPEED, unmountOnExit: true },
        React.createElement("div", { id: 'sidebarBlock', className: styles.sidebarBlock },
            React.createElement("div", { id: 'sidebar', className: styles.sidebarWrap },
                React.createElement("div", { className: styles.topSection },
                    React.createElement(DarkModeSwitcher, null),
                    React.createElement("button", { "aria-label": 'close', type: 'button', onClick: _handleClose, className: styles.closeBtn },
                        React.createElement("i", { className: 'fa fa-arrow-right' }))),
                React.createElement("div", { className: styles.mainSection },
                    React.createElement(Links, null))))));
};
export default Sidebar;
