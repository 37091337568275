var initialState = {
    searchCarNumber: null,
    carData: {
        image: null,
        mainData: null,
        collectedData: null
    },
    statuses: {
        isSearchError: false,
        isSearchInProgress: false
    },
    error: null
};
export default initialState;
