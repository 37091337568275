import LocalStorageManager from '../../utils/localStorageManager';
var _a = new LocalStorageManager(), addStore = _a.addStore, addToStore = _a.addToStore, updateStore = _a.updateStore, getStore = _a.getStore;
var isStorePresent = getStore({ storeKey: 'chat-data' });
var isInputDataPresent = getStore({ storeKey: 'chat-current-input' });
if (!isStorePresent) {
    addStore({ storeKey: 'chat-data', storeData: [] });
}
if (!isInputDataPresent) {
    addStore({ storeKey: 'chat-current-input', storeData: '' });
}
export { addToStore, updateStore, getStore };
